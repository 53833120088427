<template>
  <div class="task-allocation">
    <div class=" mt-5 p-5 bg-white rounded shadow-md" v-if="order">
      <h3 class="mb-5 mt-4">Aufgabenzuweisung</h3>
      <div v-for="(item,index) in order.items" class="taskAllocationItem mb-4">
        <div v-if="item.type == 'article'">
          <h5 class="pb-5">Pos {{index+1}}: {{item.product_name}} ({{item.amount}}
            {{unitName(item.volume_unit)}})</h5>

          <vs-alert color="#8a8a8a" :active="true" icon="error" class="mb-4"
                    v-show="!item.commisionItems">
            Keine Aufgabenzuweisung gefunden
          </vs-alert>

          <div v-if="item.commisionItems">
            <vs-table :data="item.commisionItems" :hoverFlat="false">
              <template slot="thead">
                <vs-th>
                  Benutzer
                </vs-th>
                <vs-th>
                  Menge
                </vs-th>
                <vs-th>
                  Performance %
                </vs-th>
                <vs-th>
                  Einzelpreis
                </vs-th>
                <vs-th>
                  Sales Sum
                </vs-th>
                <vs-th>
                  Status
                </vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.user_id">
                    {{tr.user.detail.first_name}} {{tr.user.detail.last_name}}
                  </vs-td>

                  <vs-td :data="tr.amount">
                    {{tr.amount}} {{unitName(tr.volume_unit)}}
                  </vs-td>

                  <vs-td :data="tr.percent_performance">
                    {{tr.percent_performance}}%
                  </vs-td>

                  <vs-td :data="tr.unit_price">
                    {{tr.unit_price}} €
                  </vs-td>

                  <vs-td :data="tr.sales_sum">
                    {{tr.sales_sum}} €
                  </vs-td>

                  <vs-td>
                    <feather-icon icon="ArchiveIcon" class="inline-block mr-2"
                                  v-if="tr.status_id == '1'" svgClasses="w-4 h-4"
                                  title="Status: Entwurf"/>
                    <feather-icon icon="ThumbsUpIcon" class="inline-block mr-2"
                                  v-if="tr.status_id == '2'" svgClasses="w-4 h-4"
                                  title="Status: Freigegeben von Mitarbeiter"/>
                    <feather-icon icon="PauseIcon" class="inline-block mr-2"
                                  v-if="tr.status_id == '3'" svgClasses="w-4 h-4"
                                  title="Status: In Klärung"/>
                    <feather-icon icon="LoaderIcon" class="inline-block mr-2"
                                  v-if="tr.status_id == '4'" svgClasses="w-4 h-4"
                                  title="Status: Freigegeben von Chef"/>
                    <feather-icon icon="AwardIcon" class="inline-block mr-2"
                                  v-if="tr.status_id == '5'" svgClasses="w-4 h-4"
                                  title="Abgerechnet"/>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OfferHeaderNavigation from "../../../components/offers-details/HeaderNavigation"
import ApiService from "../../../api";
import staticOptions from "../../../mixins/static/options";

export default {
  name: "OfferTaskAllocation",
  components: {
    OfferHeaderNavigation
  },
  data() {
    return {
      order: null
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      this.$vs.loading()
      ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
        this.$vs.loading.close()
      }).catch(response => {
      })
    },
    unitName(value) {
      let name = staticOptions.units.find((item) => item.value == value);

      if(name === null || name === undefined) {
        return null;
      }

      return name.labelShort;
    },
  }
}
</script>

<style lang="scss">

</style>
